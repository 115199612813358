/* FONTS */

@import url('https://fonts.googleapis.com/css2?family=Sarabun:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap');

/* GLOBAL STYLES */

:root {
  --font-sarabun: 'Sarabun', sans-serif;
  --font-roboto: 'Roboto Condensed', serif;
  --font-comfortaa: 'Comfortaa', sans-serif;
  --dynamics-shield-blue: #1d7ab5;
  --dynamics-shield-light-grey: #878787;
  --dynamics-shield-medium-grey: #646464;
  --dynamics-shield-dark-grey: #505050;
  --dynamics-shield-white: #ffffff;
  --header-shadow: #000 0px 0px 5px;
  
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body >* {
  margin: 0;
  max-height: 100vh;
  display: inline-block;
} 

main {
  top: 0px;
  bottom: 50px;
  /* height: 100vh; */
  height: fit-content;
}

.image {
  flex: 0 0 30%;
  margin: 5px;
}

img {
  max-width: 100%;
}

h1{
  color: var(--dynamics-shield-dark-grey);
  font-family: var(--font-roboto);
  font-weight: 200;
  position: relative;
  left: 10px;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

h4{
    color: var(--dynamics-shield-dark-grey);
    font-family: var(--font-roboto);
    margin: 2rem 0;
    font-size: 1.2rem;
    font-weight: 800;
    text-align: center;
}

h5{
  color: var(--dynamics-shield-dark-grey);
  font-family: var(--font-roboto);
  margin: 2rem 0;
  font-size: 0.9rem;
  font-weight:bold;
  text-align: center;
}

html, body {
  height: 100vh;
  display: flex;
  flex-direction: column;

  /*
  flex-flow: column;
  */

  background-color: var(--dynamics-shield-white);
  color: var(--dynamics-shield-dark-grey);
  font-family: var(--font-roboto);
  margin: 0;
  padding: 0;
  position: sticky;
}

.container {
  background-color: var(--dynamics-shield-white);
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 1rem 2rem;
  text-align: center;
}

a { 
  color: var(--dynamics-shield-dark-grey); 
  text-decoration: none;
  font-weight: lighter;
}

a:visited { 
  color: var(--dynamics-shield-dark-grey); 
  text-decoration: none; 
  font-weight: lighter;
}

a:hover { 
  color: var(--dynamics-shield-dark-grey);
  text-decoration: underline; 
}

/* HEADER */

header {
  box-shadow: var(--header-shadow);
  width: 100%;
  position:fixed;
  top:0;
  z-index: 1;
}

header .container {
  font-family: var(--font-roboto);
  font-weight: 800;
  background-color: var(--dynamics-shield-white);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;
  max-width: 100%;
  margin: 0px;
}


.logo1 {
  display: block;
}

.logo2{
  display: none;
}

.menu {
  display: flex;
  gap: 2rem;
  align-items: center;
  position: absolute;
  right: 30px;
}

nav a{
  padding: 10px;
}

#top.container{
  max-width: 100%;
  margin-top: 132px;
  padding: 0px;
  position: relative;
}

#top img{
  width: 100%
}

.title{
  position: absolute;
  color: #F3F3F3;
  font-size: 65px;
  bottom: 168px;
  right: 211px;
}

.titletext {
  position: absolute;
  color: #F3F3F3;
  font-size: 25px;
  bottom: 50px;
  right: 22px;
  inline-size: 600px; 
  overflow-wrap:normal;
  text-align: justify;
  text-justify: inter-word;
}

/* QUOTE */

#quote.container{ 
  background-color: var(--dynamics-shield-white);
  color: var(--dynamics-shield-dark-grey);
  width: 100%;
  text-align: center;
  margin: auto;
  padding: 65px 155px;
}

#quote h1{
  font-size: 25px;
  left: 12px;
  text-align: justify;
}


#quotecol.column{
  float: left;
  width: 50%;
}

#quotecol img{
  width: 420px;
  border-radius: 12px 12px 12px 12px;
}

#quotecol h4{
  width: 420px;
}

#quote h4{
  font-weight:lighter;
  text-align: justify;
  text-justify: inter-word;
  margin: 2rem 12px;
}



/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}


/* BUTTONS */

a.btn{ 
  background-color: var(--dynamics-shield-blue);
  border: none;
  color: var(--dynamics-shield-white);
  padding: 15px 32px;
  width: 25%;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin:auto;
}

a.btn:visited { 
  color: var(--dynamics-shield-white); 
  text-decoration: none; 
}

a.btn:hover { 
  background-color: var(--dynamics-shield-white);
  border: 2px solid;
  border-color: var(--dynamics-shield-blue);
  border-radius: 15px;
  color: var(--dynamics-shield-blue); 
}


/* HIGHLIGHTS */

#highlights.container{
  max-width: 100%;
  background-color: var(--dynamics-shield-blue);
  display: inline-block;
}

#highcol.column {
  float: left;
  width: 33.33%;
}


/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

#rectangle{
  width: 350px;
  height: 420px;
  background-color: var(--dynamics-shield-white);
  border-radius: 12px 12px 12px 12px;
  margin: 0px auto;
}

#rectangle h2{
  color: var(--dynamics-shield-dark-grey);
  font-family: var(--font-roboto);
  font-weight: 100;
  margin: 10px 0;
  font-size: 15px;
  text-align: center;
  margin: 0px;
  padding: 20px 0px;
}

#rectangle img{
  width: fit-content;
  height: 196px;
}

#rectangle h3{
  color: var(--dynamics-shield-dark-grey);
  font-family: var(--font-roboto);
  font-weight: 100;
  font-size: 15px;
  text-align: center;
  padding: 25px 25px;
}

/*-------------------------------------------------------------------*/
/* 404 ERROR */

#errormessage.container{
  max-width: 100%;
  margin-top: 132px;
  /* padding: 100px 0px; */
  padding: calc(8px + 1.5625vw);
  position:relative;
}

.errorpage h2 {
  color: var(--dynamics-shield-dark-grey);
}

.errorpage img{
  height: 100px;
}


/* FOOTER */

footer {
  margin: auto;
}

/* footer {
  bottom: 0;
} */

/*----------------------------------------------------------------------------------------------------*/

/* CONTACT US */

#contact.container{ 
  background-color: var(--dynamics-shield-white);
  color: var(--dynamics-shield-dark-grey);
  width: 100%;
  text-align: center;
  margin-top: 132px;
  padding: 65px 155px;
}

#contactcol h1{
  font-size: 25px;
  left: 38px;
  text-align: justify;
}


#contactcol.column{
  float: left;
  width: 50%;
}

#contactcol img{
  margin: 12px 12px;
  height: 250px;
  padding: 0px 0px;
  float: left;
}

#contactcol h4{
  width: 420px;
}

#contact h4{
  font-weight:lighter;
  text-align: justify;
  margin: 21px 40px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* FORM */
#contactform.container{
  max-width: 100%;
  background-color: var(--dynamics-shield-blue);
  display: inline-block;
  margin: auto;
  
}

#contactForm input, textarea {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 10px;
  border: none;
  display: flex;
  width: 80%;
  max-width: 500px;
  margin: 15px auto;
}

#contactForm textarea{
  height: 250px;
}

#contactForm button {
  background-color: #ffffff;
  color: var(--dynamics-shield-blue);
  font-weight: bolder;
  padding: 15px;
  border-radius: 10px;
  border: none;
  margin: 15px auto;
  width: 20%;
}

/*----------------------------------------------------------------------------------------------------*/

/* PRIVACY STATEMENT*/

/* #privacystatement.container{
  background-color: var(--dynamics-shield-light-grey);
} */

.privstatement h4{
  color: var(--dynamics-shield-dark-grey);
  font-family: var(--font-roboto);
  margin: 2rem 0;
  font-size: 17px;
}


/*----------------------------------------------------------------------------------------------------*/

/* Media queries - Responsive timeline on screens less than 1024px wide */

@media screen and (max-width: 1024px) {

  .logo1 {
    display: block;
    height: 90px;
  }

  .logo2{
    display: none;
  }

  header .container {
    padding-left: 10px;
  }

  .menu {
    size: 0.5rem;
    gap: 0.5rem;
    right: 10px;

    display: flex;
  
    align-items: center;
    position: absolute;
  
  }

  .menu a{
    font-size: 20px;
    right: 3px;
  }


  nav {
    padding: 0.6px;
    gap: 0.1rem;
    right: 3px;
  }

  nav a{
    padding: 3px;
  }

  nav img{
    width: 18px;
    height: 18px;
  }

  h2 {
    font-size: 1.5rem;
  }

  #top.container {
    margin-top: 122px;
  }


  .title {
    font-size: 30px;
    bottom: 160px;
    /* left: 360px; */
    right: 171px;
  }

  .titletext {
    font-size: 20px;
    bottom: 31px;
    right: 17px;
    inline-size: 348px;
  }

  /* QUOTE */

  #quote.container{
    text-align: center;
    padding: 25px 35px;
    margin: 0px 0px;
    max-width: 100%;
  }

  #quotecol.column {
    padding-bottom: 19px;
  }

  #quote h1 {
    left: 0px;
    font-size: 30px;
    overflow: hidden;
  }

  #quotecol h4 {
    width: 370px;
    font-size: 19px;
    margin: 0;
  }

  a.btn {
    font-size: 20px;
    width: 52%;
  }


  /* HIGHLIGHTS */

  #highlights.container {
    padding: 10px 0px;
    margin: 0px 0px;
    overflow: hidden;
  }

  #rectangle {
    width: 278px;
    height: 405px;
  }

  #rectangle h2 {
    font-size: 20px;
    padding: 7px 0px;
  }

  #rectangle img {
    height: 157px;
  }

  #rectangle h3 {
    font-size: 18px;
    padding: 7px 0px;
  }

  /* FOOTER */

  footer p{
    font-size: 22px;
  }

  #contact.container {
    margin-top: 116px;
    padding: 31px 15px;
  }

  #contactcol img {
    margin: 12px 91px;
  }
  

}

@media screen and (max-width: 912px) {

  .logo1 {
    display: block;
    height: 90px;
  }

  .logo2{
    display: none;
  }

  header .container {
    padding-left: 10px;
  }

  .menu {
    size: 0.5rem;
    gap: 0.5rem;
    right: 57px;

    display: flex;
  
    align-items: center;
    position: absolute;
  
  }

  .menu a{
    font-size: 20px;
    right: 3px;
  }


  nav {
    padding: 0.6px;
    gap: 0.1rem;
    right: 3px;
  }

  nav a{
    padding: 3px;
  }

  nav img{
    width: 18px;
    height: 18px;
  }

  h2 {
    font-size: 1.5rem;
  }

  #top.container {
    margin-top: 122px;
  }


  .title {
    font-size: 30px;
    bottom: 160px;
    /* left: 360px; */
    right: 100px;
  }

  .titletext {
    font-size: 20px;
    bottom: 31px;
    /* left: 541px; */
    right: 100px;
    inline-size: 348px;
  }

  /* QUOTE */

  #quote.container{
    text-align: center;
    padding: 25px 35px;
    margin: 0px 0px;
    max-width: 97%;
  }

  #quotecol.column {
    padding-bottom: 19px;
  }

  #quote h1 {
    left: 0px;
    font-size: 30px;
  }

  #quotecol h4 {
    width: 370px;
    font-size: 19px;
    margin: 0;
  }

  a.btn {
    font-size: 20px;
    width: 46%;
  }


  /* HIGHLIGHTS */

  #highcol.column {
    width: 32%;
  }

  #highlights.container {
    padding: 10px 0px;
  }

  #rectangle {
    width: 278px;
    height: 405px;
  }

  #rectangle h2 {
    font-size: 20px;
    padding: 7px 0px;
  }

  #rectangle img {
    height: 157px;
  }

  #rectangle h3 {
    font-size: 18px;
    padding: 7px 0px;
  }

  /* FOOTER */

  footer p{
    font-size: 22px;
  }

}

@media screen and (max-width: 820px) {

  .menu {
    right: 36px;
  }

  #quote.container {
    padding: 25px 14px;
    max-width: 94%;
  }

  #highcol.column {
    width: 32%;
  }

  #rectangle {
    width: 252px;
    height: 405px;
  }

  #rectangle img {
    height: 140px;
  }

  #contact.container {
    margin-top: 116px;
    padding: 31px 15px;
  }

  #contactcol img {
    margin: 12px 91px;
  }


}


@media screen and (max-width: 800px) {

  .menu {
    right: 78px;
  }

  header .container {
    padding-left: 10px;
    max-width: 100%;
  }

  .title {
    right: 168px;
  }

  .titletext {
    right: 54px;
    inline-size: 307px;
  }

  #quotecol h4 {
    width: 348px;
  }

  #quotecol.column {
    padding: 19px 29px;
  }

  #highcol.column {
    float: left;
    max-width: 31.33%;
}

  #rectangle {
    width: 230px;
    height: 375px;
  }

  #rectangle img {
    height: 131px;
  }

}

@media screen and (max-width: 768px) {

  #highcol.column {
    max-width: 33.33%;
    margin: 0px 4px;
  }

}

@media screen and (max-width: 712px) {

  .menu {
    right: 65px;
  }

  .title {
    font-size: 29px;
    bottom: 141px;
    right: 96px;
  }

  .titletext {
    font-size: 18px;
    bottom: 5px;
    right: 9px;
    inline-size: 272px;
  }

  #top.container {
    margin-top: 123px;
  }

  #quote.container {
    max-width: 100%;
    margin: 33px 0px;
    padding: 0px 0px;
  }

  #quote h1 {
    left: 27px;
    margin: 0px 0px;
    padding: 5px 0px;
    font-size: 25px;
  }

  #quotecol h4 {
    width: 333px;
  }

  #quotecol.column {
    padding: 0px 24px;
  }

  a.btn {
    width: 41%;
  }

  #rectangle {
    width: 200px;
    height: 412px;
  }

  #rectangle img {
    height: 112px;
  }

}


@media screen and (max-width: 600px) {

  header .container {
    max-width: 100%;
  }

}

@media screen and (max-width: 480px) {

  header .container {
    padding-left: 10px;
    max-width: 100%;
  }

}

@media screen and (max-width: 414px) {

  header .container {
    padding-left: 10px;
    max-width: 100%;
  }

  .logo1 {
    height: 56px;
  }

  .menu {
    right: 15px;
  }

  .menu a {
    font-size: 13px;
  }

  #top.container {
    margin-top: 88px;
  }

  .title {
    font-size: 18px;
    bottom: 76px;
    right: 96px;
  }

  .titletext {
    font-size: 12px;
    bottom: 5px;
    right: 15px;
    inline-size: 195px;
  }

  #quote .row{
    max-width: 100%;
  }

  #quotecol img {
    max-width: 182px;
    margin: 33px 0px;
  }

  #quote h1 {
    width: 243px;
    left: 24px;
    font-size: 18px;
  }

  #quotecol h4 {
    width: 199px;
    font-size: 12px;
    padding: 10px 0px;
  }

  a.btn {
    width: 50%;
    font-size: 12px;
  }

  #highcol.column {
    float: left;
    max-width: 30%;
    margin: 0px 6px;
  }

  #rectangle {
    width: 127px;
    height: 327px;
  }

  #rectangle h2 {
    font-size: 15px;
    padding: 7px 0px;
  }

  #rectangle img {
    height: 72px;
  }

  #rectangle h3 {
    font-size: 13px;
    padding: 7px 0px;
  }

  #contact.container {
    padding: 17px 32px;
  }

  #contact .row {
    max-width: 100%;
  }

  #contactcol h1 {
    font-size: 15px;
    left: 0px;
  }

  #contact h4 {
    margin: 21px 0px;
    inline-size: 167px;
    font-size: 12px;
  }

  #contactcol img {
    margin: 47px 30px;
    height: 117px;
    padding: 0px 0px;
  }

  footer p {
    font-size: 14px;
  }

}


@media screen and (max-width: 412px) {

  header .container {
    max-width: 100%;
  }

  .logo1 {
    display: block;
    height: 53px;
  }

  .title {
    font-size: 20px;
    bottom: 79px;
    right: 66px;
  }

  .titletext {
    font-size: 12px;
    bottom: 5px;
    right: 9px;
    inline-size: 185px;
  }

  #top.container {
    margin-top: 85px;
  }

  #quote .row{
    max-width: 100%;
  }

  #quote h1 {
    font-size: 15px;
  }

  #quote.container {
    padding: 8px 0px;
    margin: 0px 0px;
  }

  #quotecol.column {
    padding: 19px 22px;
  }

  #quotecol h4 {
    width: 196px;
    font-size: 15px;
  }

  a.btn {
    width: 56%;
    font-size: 15px;
  }

  #highlights.container {
    padding: 10px 0px;
  }

  #highcol.column {
    float: left;
    max-width: 30%;
  }

  #rectangle {
    width: 128px;
    height: 359px;
  }

  #rectangle h2 {
    font-size: 14px;
    padding: 7px 0px;
  }

  #rectangle img {
    height: 77px;
  }

  #rectangle h3 {
    font-size: 13px;
    padding: 7px 0px;
  }

  footer p {
    font-size: 15px;
  }


}

@media screen and (max-width: 393px) {

  header .container {
    padding-left: 10px;
    max-width: 100%;
  }

  .menu {
    right: 10px;
  }

  .menu a {
    font-size: 14px;
  }

  .title {
    right: 97px;
  }

  .titletext {
    font-size: 12px;
    bottom: 5px;
    right: 25px;
    inline-size: 199px;
  }

  #quote h1{
    max-width: 200px;
  }

  #quotecol h4 {
    width: 184px;
    font-size: 12px;
  }

  #highcol.column {
    float: left;
    max-width: 29.5%;
    margin: 0px 6px;
  }

  #rectangle {
    width: 120px;
    height: 336px;
  }

  #rectangle h2 {
    font-size: 13px;
    padding: 7px 0px;
  }

  #rectangle img {
    height: 69px;
  }

  #rectangle h3 {
    font-size: 12px;
    padding: 7px 0px;
  }

}

@media screen and (max-width: 384px) {

  header .container {
    padding: 16px 0px;
    max-width: 100%;
  }

  #top.container {
    margin-top: 88px;
  }

  .logo1 {
    display: block;
    height: 55px;
  }

  .menu {
    right: 10px;
  }

  .menu a {
    font-size: 14px;
  }

  .title {
    font-size: 17px;
    bottom: 70px;
    right: 63px;
  }

  .titletext {
    font-size: 10px;
    bottom: 5px;
    right: 9px;
    inline-size: 164px;
  }

  #quote .row{
    max-width: 100%;
  }

  #quote h1 {
    font-size: 15px;
    width: 200px;
  }

  #quotecol h4 {
    width: 183px;
    font-size: 11px;
    margin: 0px 3px
  }

  #quotecol.column {
    padding: 19px 24px;
  }

  #quotecol img {
    max-width: 169px;
  }

  a.btn {
    font-size: 10px;
    width: 46%;
  }

  #highcol.column {
    max-width: 31%;
    margin: 0px 4px;
  }

  #rectangle {
    width: 122px;
    height: 356px;
  }

  #rectangle h2 {
    font-size: 14px;
  }

  #rectangle img {
    height: 71px;
  }

  #rectangle h3 {
    font-size: 12px;
  }

  footer p {
    font-size: 16px;
  }

}

@media screen and (max-width: 375px) {
  #root{
    max-height: 100vh;
  }

  .logo1 {
    display: block;
    height: 51px;
  }

  .logo2{
    display: none;
  }

  header .container {
    padding-left: 10px;
    max-width: 100%;
  }

  .menu {
    size: 0.5rem;
    gap: 0.5rem;
    right: 20px;

    display: flex;
  
    align-items: center;
    position: absolute;
  
  }

  .menu a{
    font-size: 10px;
    right: 3px;
  }


  nav {
    padding: 0.6px;
    gap: 0.1rem;
    right: 3px;
  }

  nav a{
    padding: 3px;
  }

  nav img{
    width: 18px;
    height: 18px;
  }

  h2 {
    font-size: 1.5rem;
  }

  #top.container {
    margin-top: 83px;
  }

  #top img {
    max-width: 100%;
  }

  .title {
    font-size: 15px;
    bottom: 73px;
    right: 60px;
  }

  .titletext {
    font-size: 9px;
    bottom: 4px;
    right: 18px;
    inline-size: 137px;
  }

  /* QUOTE */

  #quote.container{
    margin: 0px 0px;
    text-align: center;
    padding: 23px 18px;
  }

  #quote .row{
    max-width: 100%;
    margin: 0px 0px;
  }

  #quotecol.column {
    padding: 19px 0px;
  }

  #quote h1 {
    left: 0px;
    font-size: 15px;
  }

  #quotecol h4 {
    width: 155px;
    font-size: 10px;
    margin: 0;
  }

  a.btn {
    font-size: 10px;
    width: 52%;
  }


  /* HIGHLIGHTS */

  #highlights.container {
    padding: 10px 0px;
  }

  #highcol.column {
    float: left;
    max-width: 31%;
    padding: 0px 3px;
  }

  #rectangle {
    width: 111px;
    height: 206px;
  }

  #rectangle h2 {
    font-size: 9px;
    padding: 7px 0px;
  }

  #rectangle img {
    height: 62px;
  }

  #rectangle h3 {
    font-size: 8px;
    padding: 7px 0px;
  }


  /* FOOTER */

  footer p {
    font-size: 10px;
  }

}


@media screen and (max-width: 360px) {

  header .container {
    padding-left: 10px;
    max-width: 100%;
  }

  .menu {
    right: 14px;
  }

  .title {
    right: 64px;
  }

  .titletext {
    right: 23px;
  }

  #quote h1 {
    left: 21px;
  }

  #quote.container {
    padding: 23px 0px;
  }

  #quotecol.column {
    padding: 19px 22px;
  }

  #quotecol img {
    max-width: 148px;
  }

  #highcol.column {
    max-width: 31%;
    margin: 0px 3px;
  }

  #rectangle {
    width: 105px;
  }
  
}


@media screen and (max-width: 320px) {

  header .container {
    padding-left: 2px;
    max-width: 100%;
  }

  .menu {
    right: 13px;
  }

  .title {
    bottom: 55px;
    right: 53.5px;
    font-size: 13px;
  }

  .titletext {
    font-size: 8px;
    right: 2px;
    inline-size: 134px;
    bottom: 6px;
  }

  #quote h1 {
    font-size: 14px;
    padding: 0;
  }

  #quotecol h4 {
    width: 143px;
    font-size: 8px;
  }

  #quotecol img {
    max-width: 130px;
    margin: 13px 0px;
  }

  a.btn {
    width: 62%;
  }

  #highcol.column {
    max-width: 31%;
    margin: 0px 3px;
  }

  #rectangle {
    width: 99px;
    height: 187px;
  }

  #rectangle img {
    height: 56px;
  }

  #rectangle h3 {
      font-size: 7px;
      padding: 7px 5px;
  }

}
